// use `content-index.js` at launch
import React from 'react';
import { Modal } from 'react-bootstrap';  
import styled from 'styled-components';

const StyledModal = styled(Modal)`
  /* overflow: auto; */
  /* margin-top: 70px; */
  z-index: 9999;
`

const StyledModalBody = styled(Modal.Body)`
  border: 4px solid black !important;
  border-radius: 0;
`

const PSModal = ({show, handleClose, children}) => {
  return (
    <StyledModal show={show} onHide={handleClose}>
      <StyledModalBody>
        {children}
      </StyledModalBody>
    </StyledModal>     
  )
}

export default PSModal
